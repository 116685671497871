/** @jsx jsx */
import { Container, Heading, jsx } from 'theme-ui'
import { string } from 'prop-types'

import renderRichText from '~/content/renderRichText'
import Section from '~/components/Section'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'FinePrintSection'

const PROP_TYPES = {
  elementId: string,
  body: ContentfulRichTextPropType,
  heading: string.isRequired,
}

const Component = ({
  elementId,
  heading,
  body,
}) => (
  <Section id={elementId}>
    <Container>
      <Heading
        as="h1"
        variant="heading2"
      >
        {heading}
      </Heading>
      {renderRichText(body, { style: 'small' })}
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
